<template>
  <iq-main>
    <div class="container">
      <div class="login-container">
        <div class="login-form">
          <img src="../../assets/images/hpms-96x96.svg" alt="logo" />

          <md-tabs md-alignment="centered">
            <md-tab id="login-tab" md-label="Anmelden">
              <login @loading="toggleLoading" />
            </md-tab>

            <md-tab id="register-tab" md-label="Registrieren">
              <register @loading="toggleLoading" />
            </md-tab>
          </md-tabs>
        </div>

        <div class="login-filler"></div>
      </div>
    </div>

    <iq-loader :loading="loading" />
  </iq-main>
</template>

<script>
import Login from "@/components/authorization/login";
import Register from "@/components/authorization/register";

export default {
  name: "Authorization",
  components: { Login, Register },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    toggleLoading(state) {
      this.loading = state;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.login-container {
  border-radius: 0.5rem;
  border: 1px solid var(--iq-gray-300);
  width: 100%;
  height: 100%;
  display: flex;

  & > .login-form {
    width: 600px;
    background-color: white;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 1rem;
    text-align: center;

    & > img {
      height: 96px;
      width: 96px;
      margin-bottom: 1rem;
    }
  }

  & .login-filler {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--iq-blue-800);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>
