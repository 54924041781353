/**
 * Construct the URLSearchParams
 * @param { Object } queryParams Object containing all parameters
 * @returns { URLSearchParams }
 **/
const getParams = (queryParams) => {
  let params = new URLSearchParams();

  for (let key in queryParams) {
    params.set(key, queryParams[key]);
  }

  return params;
};

/**
 * Utility to clean the payload for an API-Call
 * Removes all key-value-pairs that are null or falsy
 * @param { object } data Payload for the API-Call
 * @return { object } Cleaned payload
 **/
const cleanPayload = (data) => {
  for (let key in data) {
    if (typeof data[key] !== "boolean" && !data[key]) delete data[key];
  }
  return data;
};

export { getParams, cleanPayload };
