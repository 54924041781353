import axios from "axios";
import Settings from "@/config";

const Interceptor = axios.create();
const AUTH_URI = Settings.API_URI_AUTH;

function getAccessToken() {
  return localStorage.getItem("access_token");
}

function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${AUTH_URI}/refresh`, {
        withCredentials: true,
      })
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        resolve(response.data.access_token);
      })
      .catch((err) => {
        console.log(err);
        reject(401);
      });
  });
}

Interceptor.interceptors.request.use((req) => {
  let token = getAccessToken();
  req.headers = { Authorization: `Bearer ${token}` };
  req.withCredentials = true;
  return req;
});

Interceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config && error.response && error.response.status === 401) {
      return refreshAccessToken()
        .then((token) => {
          error.config.headers["Authorization"] = `Bearer ${token}`;
          return axios.request(error.config);
        })
        .catch((err) => {
          if (err === 401) {
            localStorage.clear();
            sessionStorage.clear();
            location.reload();
          }
        });
    }
    return Promise.reject(error);
  }
);

export default Interceptor;
