<template>
  <form @keypress.enter="login" @submit.prevent.stop>
    <md-field>
      <md-input
        type="email"
        placeholder="Email-Address"
        autocomplete="username"
        v-model="email"
      />
    </md-field>

    <md-field>
      <md-input
        type="password"
        placeholder="Password"
        autocomplete="current-password"
        v-model="password"
      />
    </md-field>

    <md-button class="md-primary button-adjuster" @click="login"
      >Anmelden</md-button
    >

    <div class="md-body-1" style="text-align: right; margin-top: 1rem">
      <router-link to="/password/forgot">Passwort vergessen?</router-link>
    </div>

    <md-snackbar
      :md-active.sync="showMsg"
      class="snackbar-adjuster snackbar-adjuster--error"
      >{{ msg }}</md-snackbar
    >
  </form>
</template>

<script>
import { loginUser } from "@/services/api/authorization";
import { getUserRole } from "@/services/api/user";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      msg: null,
      showMsg: false,
    };
  },
  methods: {
    async login() {
      // Emit the loading state
      this.emitLoading(true);
      try {
        let response = await loginUser({
          email: this.email,
          password: this.password,
        });
        // Set the access_token
        localStorage.setItem("access_token", response.data.access_token);
        await this.$store.commit(
          "setUserIsLoggedIn",
          response.data.access_token
        );
        // Get the user role
        let user_role = await getUserRole();
        localStorage.setItem("user_role", user_role);
        await this.$store.commit("setUserRole", user_role);
        // Disable loading state
        this.emitLoading(false);
        // Redirect
        await this.$router.push({ name: "Dashboard" });
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },
    handleError(error) {
      let status = error.response.status;
      let statusText = error.response.statusText;
      // No user found
      if (status === 404)
        this.msg = `${statusText}: ${error.response.data.detail}`;
      // User is not confirmed, yet
      else if (status === 403)
        this.msg = `${statusText}: ${error.response.data.detail}`;
      // Wrong password
      else if (status === 401)
        this.msg = `${statusText}: ${error.response.data.detail}`;
      // Failed dependency (validation on API-Level)
      else if (status === 422) this.msg = `${statusText}`;
      // On everything else
      else this.msg = "Request could not be handled. Please try again later.";
      // Show the error msg
      this.showMsg = true;
      // Emit the loading state
      this.emitLoading(false);
    },
    emitLoading(state) {
      this.$emit("loading", state);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-field {
  & > .md-helper-text {
    color: red;
  }
}

.md-body-1 {
  text-align: right;
  margin-top: 1rem;
}
</style>
