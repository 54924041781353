import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { cleanPayload, getParams } from "@/services/api/utils";
import "./types";

const USER_URI = Settings.API_URI_USER;

/**
 * Get user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<UserObject> } User
 * @throws { APIError }
 **/
const getUser = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${USER_URI}/`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * Automatically removes empty properties
 * @param { UserUpdateObject } data Payload
 * @return { Promise<UserObject> } Updated user
 * @throws { APIError }
 **/
const updateUser = (data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${USER_URI}/`,
      data: cleanPayload(data),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * Automatically removes empty properties
 * @param { string } id ID of the user
 * @param { UserUpdateObject } data Payload
 * @return { Promise<UserObject> } Updated user
 * @throws { APIError }
 **/
const updateUserOther = (id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${USER_URI}/other`,
      data: cleanPayload(data),
      params: getParams({ user_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get the role of a user (me) - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<string> } Updated user
 * @throws { APIError }
 **/
const getUserRole = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${USER_URI}/role`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data.user_role))
      .catch((error) => reject(error));
  });
};

/**
 * Get the company the user (me) is associated with - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<CompanyObject> } Company
 * @throws { APIError }
 **/
const getUserCompany = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${USER_URI}/company`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get all projects a user (me) is associated with - More information: {@link http://localhost:8000/docs API-Docs}
 * @return { Promise<ProjectObject[]>} Projects
 * @throws { APIError }
 **/
const getUserProjects = () => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${USER_URI}/projects`,
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export {
  getUser,
  updateUser,
  getUserRole,
  getUserCompany,
  getUserProjects,
  updateUserOther,
};
