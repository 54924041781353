<template>
  <form @keypress.enter="register" @submit.prevent.stop>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <md-field>
          <md-input
            type="text"
            placeholder="First name"
            autocomplete="given-name"
            v-model="user.first_name"
          />
        </md-field>
      </div>

      <div class="md-layout-item">
        <md-field>
          <md-input
            type="text"
            placeholder="Last name"
            autocomplete="family-name"
            v-model="user.last_name"
          />
        </md-field>
      </div>
    </div>

    <md-field>
      <md-input
        type="email"
        placeholder="Email-Address"
        autocomplete="email"
        v-model.trim="user.email"
      />
    </md-field>

    <md-field>
      <md-input
        type="password"
        placeholder="Password"
        autocomplete="new-password"
        v-model.trim="user.password"
      />
    </md-field>

    <md-field>
      <md-input
        type="password"
        placeholder="Password"
        autocomplete="off"
        v-model="confirm_password"
      />
    </md-field>

    <md-field>
      <md-input
        type="text"
        placeholder="Company name"
        autocomplete="organization"
        v-model="company.name"
      />
    </md-field>

    <!--    <md-field>-->
    <!--      <md-select v-model="company.type" md-dense>-->
    <!--        <md-option value="sponsor">Sponsor</md-option>-->
    <!--        <md-option value="center">Test center</md-option>-->
    <!--      </md-select>-->
    <!--    </md-field>-->

    <md-button class="md-primary button-adjuster" @click="register"
      >Registrieren</md-button
    >

    <md-snackbar
      :md-active.sync="showMsg"
      class="snackbar-adjuster snackbar-adjuster--error"
      >{{ msg }}</md-snackbar
    >
  </form>
</template>

<script>
import { registerUser } from "@/services/api/authorization";
import routingMixin from "@/mixins/routingMixin";

export default {
  name: "register",
  mixins: [routingMixin],
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      company: {
        name: "",
        type: "sponsor",
      },
      confirm_password: null,
      msg: null,
      showMsg: false,
    };
  },
  methods: {
    async register() {
      // Emit loading
      this.emitLoading(true);
      try {
        let response = await registerUser({
          user: this.user,
          company: this.company,
        });
        // Redirect the user
        let name = `${response.data.first_name} ${response.data.last_name}`;
        await this.redirect({ name: "Greeting", query: { full_name: name } });
      } catch (error) {
        console.error(error);
        // Handle the error
        if (error.response.status === 409)
          this.msg = error.response.data.detail;
        else if (error.response.status === 422)
          this.msg = "Please correct all fields and try again.";
        else this.msg = "There has been an error. Please try again later!";
        this.showMsg = true;
      }
      // Disable the loading state
      this.emitLoading(false);
    },
    emitLoading(state) {
      this.$emit("loading", state);
    },
  },
};
</script>

<style scoped></style>
